body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'), url('./fonts/WorkSans.ttf') format('truetype-variations');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Kanit-Medium';
  src: local('Kanit-Medium'), url('./fonts/Kanit-Medium.ttf') format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'DoHyeon';
  src: local('DoHyeon'), url('./fonts/DoHyeon.ttf') format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: local('Ubuntu-Medium'), url('./fonts/Ubuntu-Medium.ttf') format('truetype');
  font-weight: 1 999;
}

/* Basier Circle */
@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-medium-webfont/basiercircle-medium-webfont.ttf') format('truetype');
    font-weight: medium;
    font-style: normal;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.ttf') format('truetype');
    font-weight: medium;
    font-style: italic;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-regular-webfont/basiercircle-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'BasierCircle';
    src: url('./fonts/BasierCircle/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot');
    src: url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff2') format('woff2'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff') format('woff'),
         url('./fonts/BasierCircle/Basier-Circle-bold-webfont/basiercircle-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/* Basier Square */
@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-bold-webfont/basiersquare-bold-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-bold-webfont/basiersquare-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-bold-webfont/basiersquare-bold-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-bold-webfont/basiersquare-bold-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-bold-webfont/basiersquare-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-bolditalic-webfont/basiersquare-bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-medium-webfont/basiersquare-medium-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-medium-webfont/basiersquare-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-medium-webfont/basiersquare-medium-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-medium-webfont/basiersquare-medium-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-medium-webfont/basiersquare-medium-webfont.ttf') format('truetype');
    font-weight: medium;
    font-style: normal;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-mediumitalic-webfont/basiersquare-mediumitalic-webfont.ttf') format('truetype');
    font-weight: medium;
    font-style: italic;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-regular-webfont/basiersquare-regular-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-regular-webfont/basiersquare-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-regular-webfont/basiersquare-regular-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-regular-webfont/basiersquare-regular-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-regular-webfont/basiersquare-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-regularitalic-webfont/basiersquare-regularitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-semibold-webfont/basiersquare-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'BasierSquare';
    src: url('./fonts/BasierSquare/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.eot');
    src: url('./fonts/BasierSquare/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BasierSquare/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.woff2') format('woff2'),
         url('./fonts/BasierSquare/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.woff') format('woff'),
         url('./fonts/BasierSquare/Basier-Square-semibolditalic-webfont/basiersquare-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}